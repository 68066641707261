<script setup>
import ModifierBuilder from "@/components/EditModifier/ModifierBuilder.vue"
import { useGlobalStore } from "@/stores/global"
import { configGlobalModifiers } from "~/utils/config"
import UcAlert from "@/components/UC/UcAlert.vue"
import { provide } from "vue"

provide("currentFormType", null)

const store = useGlobalStore()

const maxGlobalModifiers = configGlobalModifiers.maxGlobalModifiers

function deleteModifierHandler(modifier) {
  store.deleteGlobalModifier(modifier)
}

function modifierSavedHandler(modifier) {
  if (!modifier.id) {
    // This is a new modifier. Assign an ID.
    modifier.id = crypto.randomUUID()
  }
  store.writeToModifiersGlobal(modifier)
}

function resetModifiersHandler() {
  store.initModifiersGlobal()
}

function searchModifierSelectedHandler(modifier) {
  store.writeToModifiersGlobal(modifier)
}
</script>

<template>
  <div>
    <UcAlert v-model="store.alertExplainScopeGlobal" closable class="mb-4">
      Global modifiers are applied to the entire attack sequence no matter what
      profiles or weapons are used. Explore common presets and/or create your
      own from scratch.
    </UcAlert>

    <ModifierBuilder
      :modifiers="store.modifiersGlobal"
      modifier-scope="global"
      :modifiers-max-count="maxGlobalModifiers"
      :table-show-select="true"
      @delete-modifier="deleteModifierHandler"
      @modifier-saved="modifierSavedHandler"
      @reset-modifiers="resetModifiersHandler"
      @search-modifier-selected="searchModifierSelectedHandler"
    />
  </div>
</template>
